ul {
    padding: 0;
    margin: 1rem;
}

li {
    /* Remove list styling */
    list-style-type: none;
    margin: 0;
}

.technologies-pill {
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    gap: 1rem;
    max-width: 75%;
}

.pill {
    font-family: 'Source Code Pro';
    background-color: #25534345;
    color: #7fffd4;
    padding: .25rem .7rem .25rem .7rem;
    border-radius: 20px;
    font-size: 9pt;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
    transition: all 0.25s ease-in-out;
    user-select: none;
}

.pill:hover {
    box-shadow: 2px 6px 10px rgba(0, 0, 0, 0.2);
    transform: translateY(-2px);
    color: #95fad8;
    cursor: default;
}