/* Stylesheet for index.js - For global/generic styling */
/* Import the Montserrat typeface */
@import "~@fontsource/montserrat/100.css"; /* Weight 100 */
@import "~@fontsource/montserrat/200.css"; /* Weight 200 */
@import "~@fontsource/montserrat/300.css"; /* Weight 300 */
@import "~@fontsource/montserrat/400.css"; /* Weight 400 */
@import "~@fontsource/montserrat/500.css"; /* Weight 500 */
@import "~@fontsource/montserrat/600.css"; /* Weight 600 */
@import "~@fontsource/montserrat/700.css"; /* Weight 700 */
@import "~@fontsource/montserrat/800.css"; /* Weight 800 */
@import "~@fontsource/montserrat/900.css"; /* Weight 900 */

@import "~@fontsource/montserrat/100-italic.css"; /* Weight 100 Italic */
@import "~@fontsource/montserrat/200-italic.css"; /* Weight 200 Italic */
@import "~@fontsource/montserrat/300-italic.css"; /* Weight 300 Italic */
@import "~@fontsource/montserrat/400-italic.css"; /* Weight 400 Italic */
@import "~@fontsource/montserrat/500-italic.css"; /* Weight 500 Italic */
@import "~@fontsource/montserrat/600-italic.css"; /* Weight 600 Italic */
@import "~@fontsource/montserrat/700-italic.css"; /* Weight 700 Italic */
@import "~@fontsource/montserrat/800-italic.css"; /* Weight 800 Italic */
@import "~@fontsource/montserrat/900-italic.css"; /* Weight 900 Italic */

/* Import Source Code Pro Typeface */
@import "~@fontsource/source-code-pro/200.css";
@import "~@fontsource/source-code-pro/400.css";

/* Variables */
:root {
  --primary-color: #7fffd4;
  --background-color: #17191f;
  --title-color: #cdd2d9;
  --subtitle-color: #bfc4cb;
  --text-color: #b2b8c1;
  --secondary-color: #303847;
  --accent-color: #00ffaa;
}

html {
  scroll-behavior: smooth;
  background: var(--background-color);
  /* background: linear-gradient(to top left, red, orange, yellow, green, blue, indigo, violet);
   */
  /* background: linear-gradient(-155deg, var(--secondary-color), var(--background-color));
  dither: true;
  background-size: 200% 200%;
  background-position: top left;
  animation: smoothGradient 60s linear infinite; */
}

@keyframes smoothGradient {
  0% {
    background-position: top left;
  }
  50% {
    background-position: bottom right;
  }
  100% {
    background-position: top left;
  }
}
body {
  margin: 0;
  padding: 0;
  font-family: 'Montserrat';
  font-size: 11pt;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #1d212aed;
  /* Background blur */
  /* backdrop-filter: blur(50px) hue-rotate(90deg); */
  /* Rainbow gradient to top right */
  width: 100%;
  color: var(--text-color);
  font-weight: 500;
}

body, h1, h2, h3, p {
  margin: 0;
  padding: 0;
}

h1, h2, h3, p {
  line-height: 1.5;
}

a {
  text-decoration: none;
  color: var(--primary-color);
}

h1 {
  color: var(--title-color);
}

h2, h3 {
  color: var(--subtitle-color);
}

.experience-title {
  font-size: 1.17em;
  font-weight: bold;
  color: var(--subtitle-color);
}

.main-body {
  margin: 0 auto;
  width: 75%;
  max-width: 60rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  padding: 2rem;
}

/* Loading for lazy images */
.loading {
  filter: blur(5px);
  background-color: var(--secondary-color);
}

/* Responsive Design: Mobile */
@media (max-width: 768px) {
  .main-body {
    width: 85%;
    padding: 1rem;
  }
}

.code {
  font-family: 'Source Code Pro';
}

/* Generic Style Classes */
.hide {
  display: none;
}

.show {
  display: block;
}

.bold-text {
  font-weight: 800;
}


.title {
  font-size: calc(min(5rem, 2rem + 2vw));
  color: var(--text-color);
}

.subtitle {
  font-size: calc(min(4rem, 1rem + 2vw));
  color: #9E9E9E;
}

.heading {
  font-size: 2.5rem;
  color: var(--text-color);
  transition: all 0.15s ease-in-out;
}

@media (max-width: 768px) {
  .heading {
    font-size: 2rem;
  }
}

.highlight {
  color: var(--primary-color);
  transition: all 0.3s ease-in-out;
}

.highlight {
  display: inline-block;
  padding-bottom:2px;
  background-image: linear-gradient(120deg, var(--primary-color), var(--primary-color));
  background-position: 0 100%; /*OR bottom left*/
  background-size: 0% 1px;
  background-repeat: no-repeat;
  transition:
    background-size 0.3s,
    background-position 0s 0.3s; /*change after the size immediately*/
}

.highlight:hover {
  background-position: 100% 100%; /*OR bottom right*/
  background-size: 100% 1px;
}

.button {
  text-decoration: none;
  padding: 5px 10px;
  color: var(--primary-color);
  border: var(--primary-color) 2px solid;
  border-radius: 5px;
  width: fit-content;
  transition: all 0.2s ease-in-out;
}

.button:hover {
  box-shadow: 3px 3px 0 var(--primary-color);
  transform: translate(-3px, -3px);
}

.image {
  width: 100%;
  height: auto;
  filter: grayscale(100%) sepia(100%) hue-rotate(90deg);
    background-color: var(--accent-color);
  transition: filter 0.15s ease-in-out;
}

.img-container {
  display: flex;
  /* justify-content: center;
  align-items: center; */
  border: var(--primary-color) 2px solid;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  height: auto;
  transition: all 0.2s ease-in-out;
}

.img-container:hover {
  box-shadow: 8px 8px 0 var(--primary-color);
  transform: translate(-8px, -8px);
}

.image:hover {
  filter: grayscale(0%);
}

.bullet {
  color: #7fffd4a5;
  font-size: .8rem;
  align-self: flex-start;
  margin-top: .25rem;
  transition: all 0.2s ease-in-out;
}

/* Wave emoji animation */
.wave {
  animation-name: wave-animation;  /* Refers to the name of your @keyframes element below */
  animation-duration: 2.5s;  /* Change to speed up or slow down */
  animation-iteration-count: infinite;  /* Never stop waving :) */
  transform-origin: 70% 70%;  /* Pivot around the bottom-left palm */
  display: inline-block; /* Make it into an inline element */
}

/* Keyframes for waving hand */
@keyframes wave-animation {
  0% { transform: rotate( 0.0deg) }
  10% { transform: rotate(14.0deg) }
  20% { transform: rotate(-8.0deg) }
  30% { transform: rotate(14.0deg) }
  40% { transform: rotate(-4.0deg) }
  50% { transform: rotate(10.0deg) }
  60% { transform: rotate( 0.0deg) } /* Return to natural position */
  100% { transform: rotate( 0.0deg) } /* Return to natural position */
}

/* Fade in - for page load */
.initial-state {
  opacity: 0;
  transform: translateY(-20px); /* Adjust as needed */
  transition: opacity .5s ease-in-out, transform .5s ease-in-out;
}

.final-state {
  opacity: 1;
  transform: translateY(0);
  transition: opacity .5s ease-in-out, transform .5s ease-in-out;
}

.icon {
  width: 1.5rem;
  height: 1.5rem;
  transform: translate(0, 0);
  transition: all 0.15s ease-in-out;
}