.experience {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 1rem;
}

.textBody {
    margin-left: 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    transition: all 0.2s ease-in-out;
}

.textBody:hover .bullet {
    box-shadow: 2px 6px 10px rgba(0, 0, 0, 0.2);
    color: #29ffb8;
    transform: translateX(.35rem);
}

/* .technologies-pill {
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    gap: 1rem;
    max-width: 75%;
} */

/* .pill {
    font-family: 'Source Code Pro';
    background-color: #25534345;
    color: #7fffd4;
    padding: .25rem .7rem .25rem .7rem;
    border-radius: 20px;
    font-size: 9pt;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
    transition: all 0.25s ease-in-out;
    user-select: none;
} */

/* .pill:hover {
    box-shadow: 2px 6px 10px rgba(0, 0, 0, 0.2);
    transform: translateY(-2px);
    color: #95fad8;
    cursor: default;
} */

.featured {
    font-family: 'Source Code Pro';
    color: #7fffd4;
    font-style: italic;
    font-size: 11pt;
}

.duration {
    font-family: 'Source Code Pro';
    font-size: 10pt;
    color: #a1a7b1;
}